import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Box,
  Container,
  Grid,
  Link,
  Typography,
  Stack,
  IconButton,
} from "@mui/material";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";

import AdBanner from "../components/AdBanner";
import Quize from "../components/Quize";
import {
  getChallengeById,
  getOtherChallenges,
  getChallengesByName,
} from "../helper/program.helper";
import { logEngagement } from "../helper/log.helper";
import OtherChallengeItem from "../components/OtherChallengeItem";
import ISISection from "../components/ISISection";
import Pairs from "../components/Pairs";
import GameResult from "../components/GameResult";
import PoirotGame from "../components/PoirotGame";

function decodeHtml(html) {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}

const Challenge = () => {
  const { challengeName } = useParams();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const challengeId = searchParams.get("challengeid");
  const npi = searchParams.get("npi");
  const utmSource = searchParams.get("utm_source");
  const utmMedium = searchParams.get("utm_medium");

  const settings = useSelector((state) => state.siteSettings);
  const ipAddress = useSelector((state) => state.ipAddress);

  const [challenge, setChallenge] = useState();
  const [otherChallenges, setOtherChallenges] = useState([]);
  const [symptoms, setSymptoms] = useState([]);
  const [conditions, setConditions] = useState([]);
  const [displayTime, setDisplayTime] = useState(10);
  const [open, setOpen] = useState(false);
  const [gameResult, setGameResult] = useState(false);
  const [gameOver, setGameOver] = useState(false);
  const [response, setResponse] = useState({
    status: false,
    message: "",
  });

  useEffect(() => {
    if (ipAddress !== "" && challenge) {
      let payload = {
        npi: npi,
        ipAddress: ipAddress,
        engagementRefId: "Challenge",
        engagementSecRefId: challenge.id,
        engagementType: "Page View",
        utmSource: utmSource,
        utmMedium: utmMedium,
        timeToComplete: "",
        gameResult: "",
      };
      logEngagement(payload);

      payload = {
        npi: npi,
        ipAddress: ipAddress,
        engagementRefId: "Challenge",
        engagementSecRefId: challenge.id,
        engagementType: "Challenge Started",
        utmSource: utmSource,
        utmMedium: utmMedium,
        timeToComplete: "",
        gameResult: "",
      };
      logEngagement(payload);
    }
  }, [ipAddress, challenge]);

  useEffect(() => {
    if (challengeId) {
      
      getChallengeById(challengeId)
        .then(({ challenge }) => {
          setChallenge(challenge);

          if (challenge.additional_challenges) {
            let array = challenge.additional_challenges
              .split(",")
              .filter((c) => c && c !== "");

            getOtherChallenges(array)
              .then(({ challenges }) => {
                setOtherChallenges(challenges);
              })
              .catch((errorMessage) => {
                setOpen(true);
                setResponse({
                  status: false,
                  message: errorMessage,
                });
              });
          }
        })
        .catch((errorMessage) => {
          setOpen(true);
          setResponse({
            status: false,
            message: errorMessage,
          });
        });
    } else {
     const decodedChallengeName = challengeName?.replace(/-/g, " ");
      getChallengesByName(decodedChallengeName)
        .then(({ challenge }) => {
          setChallenge(challenge);

          getOtherChallenges(challenge.id, challenge.challenge_pack_id)
            .then(({ challenges }) => {
              setOtherChallenges(challenges);
            })
            .catch((errorMessage) => {
              setOpen(true);
              setResponse({
                status: false,
                message: errorMessage,
              });
            });
        })
        .catch((errorMessage) => {
          setOpen(true);
          setResponse({
            status: false,
            message: errorMessage,
          });
        });
    }
  }, [challengeName, challengeId]);

  useEffect(() => {
    if (challenge && challenge.type === "Poirot" && challenge.gameSection) {
      const gameSection = JSON.parse(challenge.gameSection);
      let newSymtoms = [];

      gameSection.clues.forEach((clue, index) => {
        newSymtoms[index] = {
          clue: clue,
          points: 2 * gameSection.clues.length - 1,
          image: JSON.parse(challenge.images)[index],
        };
      });

      setSymptoms(newSymtoms);
      setConditions(gameSection.correctAnswer);
      setDisplayTime(gameSection.displayTime);
    }
  }, [challenge]);

  const evaluteResult = (result) => {
    setGameResult(result);
    setGameOver(true);
  };

  return (
    <>
      {challenge && challenge.is_active === "1" ? (
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: { xs: 0, sm: 3 },
            py: { xs: 0 },
            px: { xs: 0 },
            textAlign: { sm: "center", md: "left" },
            maxWidth: "unset !important",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: { xs: 2, sm: 3 },
              py: { xs: 0 },
              px: { xs: 0 },
              textAlign: { sm: "center", md: "left" },
              width: "100%",
            }}
            id="main"
          >
            <Box
              width={"100%"}
              px={6}
              py={2}
              borderBottom={"1px solid #707070"}
            >
              <Box
                component={"img"}
                width={{ sm: "unset", xs: "60%" }}
                src={
                  settings && settings.site_logo !== ""
                    ? process.env.REACT_APP_API_IMAGE_URL + settings.site_logo
                    : "/assets/images/POCN_Logo_TM_285x120.png"
                }
              ></Box>
            </Box>
            <Box
              width="100%"
              px={{ sm: 6, xs: 2 }}
              display="flex"
              flexDirection="column"
              gap={{ sm: 3, xs: 2 }}
              alignItems="center"
            >
              {challenge && (challenge.pi_link || challenge.isi_link) && (
                <Box
                  display={{ sm: "flex", xs: "flex" }}
                  flexDirection={{ md: "row", xs: "column" }}
                  justifyContent={{ sm: "center" }}
                >
                  {challenge.pi_link && (
                    <Link
                      href={challenge.pi_link}
                      target="_blank"
                      sx={{
                        color: `${
                          challenge &&
                          challenge.pl_color &&
                          challenge.pl_color !== ""
                            ? challenge.pl_color
                            : "#4933DA"
                        }`,
                        fontWeight: 700,
                        fontFamily: "'Poppins'",
                        pr: { md: 1, xs: 0 },
                        borderRight: {
                          md: `1px solid ${
                            challenge &&
                            challenge.pl_color &&
                            challenge.pl_color !== ""
                              ? challenge.pl_color
                              : "#4933DA"
                          }`,
                          sm: "none",
                        },
                        textDecoration: "underline",
                      }}
                    >
                      US Full Prescribing Information
                    </Link>
                  )}
                  {challenge.isi_link && (
                    <Link
                      href={challenge.isi_link}
                      target="_blank"
                      sx={{
                        color: `${
                          challenge &&
                          challenge.pl_color &&
                          challenge.pl_color !== ""
                            ? challenge.pl_color
                            : "#4933DA"
                        }`,
                        fontWeight: 700,
                        fontFamily: "'Poppins'",
                        ml: { md: 1, xs: 0 },
                        textDecoration: "underline",
                      }}
                    >
                      Important Safety Information
                    </Link>
                  )}
                </Box>
              )}
              {challenge && challenge.brand_logo && (
                <Box
                  component={"img"}
                  src={
                    process.env.REACT_APP_API_IMAGE_URL + challenge.brand_logo
                  }
                  width={{ sm: "unset", xs: "50%" }}
                  sx={{ mt: -1 }}
                ></Box>
              )}
              {challenge && challenge.indication_info && (
                <Box
                  border={`1px solid ${
                    challenge.indication_color
                      ? challenge.indication_color
                      : "#4933DA"
                  }`}
                  px={2}
                  py={2}
                  borderRadius={2}
                >
                  <Typography
                    sx={{
                      color: "#4933DA",
                      textAlign: "left",
                      px: { sm: 6, xs: 0 },
                    }}
                    className="challenge-indication"
                    dangerouslySetInnerHTML={{
                      __html: decodeHtml(challenge.indication_info),
                    }}
                  />
                </Box>
              )}
              {challenge &&
                challenge.banner_ad_id &&
                challenge.banner_ad_id !== 0 && <AdBanner />}
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              gap={2}
              width="100%"
              px={{ md: 6, xs: 3 }}
              pt={{ md: 10 }}
              pb={{ md: 5, xs: 3 }}
              sx={{
                backgroundImage: `url('${
                  challenge && challenge.challenge_bg_image
                    ? process.env.REACT_APP_API_IMAGE_URL +
                      challenge.challenge_bg_image
                    : "/assets/images/default challenge bg.png"
                }')`,
                backgroundSize: { md: "cover", xs: "contain" },
                backgroundRepeat: "no-repeat",
                bgcolor: "#001232",
              }}
            >
              <Container
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  mt: { md: 14, sm: 9, xs: 9 },
                  px: { xs: 0 },
                }}
              >
                <Typography
                  sx={{
                    fontSize: { md: "42px", sm: "38px", xs: "24px" },
                    fontWeight: 700,
                    color: "#FFFFFF",
                    textAlign: "center",
                  }}
                >
                  {challenge && challenge.description ? (
                    <div
                      className="pack-description"
                      dangerouslySetInnerHTML={{
                        __html: decodeHtml(challenge.description),
                      }}
                    />
                  ) : (
                    `Take the ${challenge ? challenge.name : ""} Challenge!`
                  )}
                </Typography>
                {challenge &&
                  challenge.type === "Quiz" &&
                  challenge.quizzes && <Quize challenge={challenge} />}
                {challenge &&
                  challenge.type === "Pairs" &&
                  (gameOver ? (
                    <GameResult
                      faildMessage={
                        JSON.parse(challenge.gameSection).timeUpMessage
                      }
                      successMessage={
                        JSON.parse(challenge.gameSection).successMessage
                      }
                      isSuccess={gameResult}
                      moreUrl={challenge.more_url}
                      challengeId={challenge.id}
                    />
                  ) : (
                    <Pairs
                      defaultImage={challenge.image}
                      images={JSON.parse(challenge.images)}
                      timeToSolve={
                        JSON.parse(challenge.gameSection).timeToSolve
                      }
                      result={evaluteResult}
                    />
                  ))}
                {challenge &&
                  challenge.type === "Poirot" &&
                  symptoms.length !== 0 && (
                    <PoirotGame
                      symptoms={symptoms}
                      conditions={conditions}
                      displayTime={displayTime}
                      faildMessage={
                        JSON.parse(challenge.gameSection).timeUpMessage
                      }
                      successMessage={
                        JSON.parse(challenge.gameSection).successMessage
                      }
                      challengeId={challenge.id}
                      moreUrl={challenge.more_url}
                    />
                  )}
              </Container>
            </Box>
          </Box>
          {otherChallenges.filter((oc) => oc.is_active === "1").length !==
            0 && (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              gap={2}
              width="100%"
              px="4%"
              pt={{ md: 0, sm: 0, xs: 0 }}
              pb={7}
              bgcolor="#001232"
              mt={-3}
            >
              <Typography
                sx={{
                  fontSize: { md: "30px", sm: "28px", xs: "24px" },
                  fontWeight: 600,
                  color: "#FFFFFF",
                  textAlign: "center",
                  px: 0,
                  pt: 0,
                  pb: 1.5,
                  borderBottom: "2px solid #fff",
                }}
              >
                Additional Challenges
              </Typography>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={4}
              >
                {otherChallenges
                  .filter((oc) => oc.is_active === "1")
                  .map((otherChallenge, index) => {
                    return (
                      <Grid item md={4} sm={6} xs={12}>
                        <OtherChallengeItem
                          challengeId={otherChallenge.id}
                          name={otherChallenge.name}
                          link={`${
                            otherChallenge.program_id ? "/program" : ""
                          }${
                            otherChallenge.challenge_pack_id ? "/pack" : ""
                          }/challenge/${
                            otherChallenge.short_url &&
                            challenge.short_url !== ""
                              ? otherChallenge.short_url
                              : otherChallenge.name
                          }?challengeid=${otherChallenge.id}`}
                        />
                      </Grid>
                    );
                  })}
              </Grid>
            </Box>
          )}
          {challenge && challenge.optional_content && (
            <Box
              width="100%"
              px="4%"
              pb={5}
              dangerouslySetInnerHTML={{
                __html: decodeHtml(challenge.optional_content),
              }}
            ></Box>
          )}

          {challenge && challenge.isi && (
            <ISISection
              isiText={challenge.isi}
              isSticky={challenge.stiky_isi === "1"}
            />
          )}
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={2}
            width="100%"
            px={{ md: 6, sm: 4, xs: 3 }}
            py={5}
          >
            {challenge && challenge.company_logo && (
              <Box
                component="img"
                src={
                  process.env.REACT_APP_API_IMAGE_URL + challenge.company_logo
                }
                width={{ sm: "unset", xs: "50%" }}
                alt="logo of sitemark"
              ></Box>
            )}
            {challenge && challenge.company_info && (
              <Typography
                sx={{
                  textAlign: "center",
                  width: { md: "50%", sm: "70%", xs: "100%" },
                }}
                dangerouslySetInnerHTML={{
                  __html: decodeHtml(challenge.company_info),
                }}
              ></Typography>
            )}
            {challenge &&
              (challenge.legal_notice_link ||
                challenge.privacy_policy_link) && (
                <Box>
                  {challenge.legal_notice_link && (
                    <Link
                      href={challenge.legal_notice_link}
                      target="_blank"
                      sx={{
                        color: challenge.ln_pp_color
                          ? challenge.ln_pp_color
                          : "#DA00B5",
                        pr: 2,
                        borderRight: `${
                          challenge.privacy_policy_link
                            ? "1px solid #4933DA"
                            : "none"
                        }`,
                      }}
                    >
                      Legal Notice
                    </Link>
                  )}
                  {challenge.privacy_policy_link && (
                    <Link
                      href={challenge.privacy_policy_link}
                      target="_blank"
                      sx={{
                        color: challenge.ln_pp_color
                          ? challenge.ln_pp_color
                          : "#DA00B5",
                        ml: 2,
                      }}
                    >
                      Privacy Policy
                    </Link>
                  )}
                </Box>
              )}
          </Box>
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: { xs: 4, sm: 8 },
              py: { xs: 3, sm: 4 },
              px: { xs: 6 },
              textAlign: { sm: "center", md: "left" },
              bgcolor: "#fff",
              borderTop: "1px solid #707070",
              maxWidth: "unset !important",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: { md: "row", sm: "column", xs: "column" },
                gap: { md: 5, sm: 4, xs: 4 },
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: { md: "flex-start", sm: "center", xs: "center" },
                  gap: 4,
                  width: { md: "45%", sm: "100%" },
                }}
              >
                <Box
                  component="img"
                  src={`${
                    settings && settings.footer_logo
                      ? process.env.REACT_APP_API_IMAGE_URL +
                        settings.footer_logo
                      : "/assets/images/POCN_Logo-White_TM_285x120.png"
                  }`}
                  alt="logo"
                ></Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { md: "row", sm: "column", xs: "column" },
                  gap: { md: 5, sm: 3, xs: 1 },
                  width: { md: "50%", sm: "100%", xs: "100%" },
                  justifyContent: "space-between",
                }}
              >
                <Box display="flex" flexDirection="column" gap={1}>
                  <Link
                    href={
                      settings && settings.privacy_policy_link
                        ? settings.privacy_policy_link
                        : "#"
                    }
                    target="_blank"
                    sx={{ color: "#001232" }}
                  >
                    Privacy Policy
                  </Link>
                  <Link
                    href={
                      settings && settings.terms_link
                        ? settings.terms_link
                        : "#"
                    }
                    target="_blank"
                    sx={{ color: "#001232" }}
                  >
                    Terms of Use
                  </Link>
                </Box>
                <Box display="flex" flexDirection="column" gap={1}>
                  <Link
                    href={
                      settings && settings.contact_us_link
                        ? settings.contact_us_link
                        : "#"
                    }
                    target="_blank"
                    sx={{ color: "#001232" }}
                  >
                    Contact Us
                  </Link>
                  <Link
                    href={
                      settings && settings.learning_hub_link
                        ? settings.learning_hub_link
                        : "#"
                    }
                    target="_blank"
                    sx={{ color: "#001232" }}
                  >
                    Learning Hub
                  </Link>
                </Box>
                <Box display="flex" flexDirection="column" gap={1}>
                  <Link
                    href={
                      settings && settings.pocn_plus_link
                        ? settings.pocn_plus_link
                        : "#"
                    }
                    target="_blank"
                    sx={{ color: "#001232" }}
                  >
                    POCN+
                  </Link>
                  <Link
                    href={
                      settings && settings.mentor_program_link
                        ? settings.mentor_program_link
                        : "#"
                    }
                    target="_blank"
                    sx={{ color: "#001232" }}
                  >
                    Mentor Program
                  </Link>
                </Box>
                <Box display="flex" flexDirection="column" gap={1}>
                  <Link
                    href={`${
                      settings && settings.be_a_leader_link
                        ? settings.be_a_leader_link
                        : "https://www.pocn.com/ambassador-program/"
                    }`}
                    target="_blank"
                    sx={{ color: "#001232" }}
                  >
                    Be a Leader
                  </Link>
                </Box>
              </Box>
            </Box>
            <Box
              width="100%"
              py={3}
              display="flex"
              flexDirection={{ sm: "row", xs: "column" }}
              justifyContent="space-between"
              alignItems="center"
              borderTop="1px solid #707070"
            >
              <Typography
                sx={{
                  fontSize: { md: "17px", sm: "16px", xs: "14px" },
                  fontWeight: 400,
                  color: "#414042",
                  textAlign: { sm: "left", xs: "center" },
                }}
              >
                {settings && settings.copyright_text
                  ? settings.copyright_text
                  : "© 2024 Point Of Care Network, LLC. All Rights Reserved."}
              </Typography>
              <Stack
                direction="row"
                justifyContent="left"
                spacing={1}
                useFlexGap
                sx={{
                  color: "text.secondary",
                }}
              >
                <IconButton
                  color="inherit"
                  href={`${
                    settings && settings.facebook_link
                      ? settings.facebook_link
                      : "https://facebook.com/POCNInc"
                  }`}
                  target="_blank"
                  aria-label="facebook"
                  sx={{ alignSelf: "center" }}
                >
                  <FacebookRoundedIcon fontSize="medium" />
                </IconButton>
                <IconButton
                  color="inherit"
                  href={`${
                    settings && settings.twitter_link
                      ? settings.twitter_link
                      : "https://twitter.com/POCNInc"
                  }`}
                  target="_blank"
                  aria-label="twitter"
                  sx={{ alignSelf: "center" }}
                >
                  <TwitterIcon fontSize="medium" />
                </IconButton>
                <IconButton
                  color="inherit"
                  href={`${
                    settings && settings.linkedin_link
                      ? settings.linkedin_link
                      : "https://www.linkedin.com/company/pocn/"
                  }`}
                  target="_blank"
                  aria-label="linkedin"
                  sx={{ alignSelf: "center" }}
                >
                  <LinkedInIcon fontSize="medium" />
                </IconButton>
                <IconButton
                  color="inherit"
                  href={`${
                    settings && settings.instagram_link
                      ? settings.instagram_link
                      : "https://www.instagram.com/pocninc/"
                  }`}
                  target="_blank"
                  aria-label="instagram"
                  sx={{ alignSelf: "center" }}
                >
                  <InstagramIcon fontSize="medium" />
                </IconButton>
              </Stack>
            </Box>
          </Container>
        </Container>
      ) : (
        <Container
          component="main"
          maxWidth="xs"
          sx={{
            mt: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box sx={{ my: 5 }}>
            <Typography variant="h4" component="h1" gutterBottom>
              Inactive Page
            </Typography>
            <Typography variant="subtitle1">
              This page is currently under construction or not available for
              public view.
            </Typography>
          </Box>
        </Container>
      )}
    </>
  );
};

export default Challenge;
